.contentLayout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: inherit;
    min-height: inherit;
}

.layout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: inherit;
    height: inherit;
}

#root {
    min-height: inherit;
    height: inherit;
}

html,
body {
    min-height: 80vh;
}

html {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    text-transform: none !important;
    text-rendering: optimizeLegibility !important;
}

header {
    height: 50px !important;
}


@primary-color: #755485;@heading-color: black;@layout-header-background: #fff;@text-color: rgba(0, 0, 0, 0.65);@modal-header-border-width: 0px;@modal-header-padding-vertical: 10px;@modal-header-padding-horizontal: 15px;@modal-body-padding: 15px;@btn-circle-size-sm: 15px;@btn-height-sm: 15px;