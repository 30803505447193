.rightSider {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-flow: column wrap;
  align-items: flex-end;
  width: 20%;
}

.imprint {
  padding-bottom: 20px;
  padding-right: 20px;

  font-family: adelle,serif;
  font-weight: 700;
  font-style: normal;

}

@primary-color: #755485;@heading-color: black;@layout-header-background: #fff;@text-color: rgba(0, 0, 0, 0.65);@modal-header-border-width: 0px;@modal-header-padding-vertical: 10px;@modal-header-padding-horizontal: 15px;@modal-body-padding: 15px;@btn-circle-size-sm: 15px;@btn-height-sm: 15px;