h3 {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px;
}

h2 {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px;
}

h1 {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
    font-size: 16px;
    font-style: italic;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px;
}

dl, dt, dd, ol, li, p {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 26.4px;
    padding-right: 5px;
}


h1, h2, h3, dl, dt, dd, ol, li, p {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
}