.cardRow {
  display: flex;
  align-items: flex-end;
  padding: 16px;
}

.consultWrapper {
  display: flex;
  flex-flow: column;
  width: 50%;
  justify-content: center;
}

h3 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

h2 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

h1 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

span {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

dl, dt, dd, ol, li, p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 26.4px;
  padding-right: 5px;
}
@primary-color: #755485;@heading-color: black;@layout-header-background: #fff;@text-color: rgba(0, 0, 0, 0.65);@modal-header-border-width: 0px;@modal-header-padding-vertical: 10px;@modal-header-padding-horizontal: 15px;@modal-body-padding: 15px;@btn-circle-size-sm: 15px;@btn-height-sm: 15px;