.leftSider {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-flow: column wrap;
  align-items: flex-start;
  padding-left: 20px;
  padding-bottom: 20px;
  width: 20%;
  font-family: adelle,serif;
  font-weight: 700;
  font-style: normal;

  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

@primary-color: #755485;@heading-color: black;@layout-header-background: #fff;@text-color: rgba(0, 0, 0, 0.65);@modal-header-border-width: 0px;@modal-header-padding-vertical: 10px;@modal-header-padding-horizontal: 15px;@modal-body-padding: 15px;@btn-circle-size-sm: 15px;@btn-height-sm: 15px;