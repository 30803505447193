.content {
  display: flex;
  flex-flow: column ;
}

.modal {
  overflow-y: auto;
  max-height: 150px;
}

h3 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

h2 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;

}

h1 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}


.videoWrapper {
  width: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.videoWrapper img {
  width: 100%;
  height: auto;
}

.videoWrapper .button0 {
  position: absolute;
  top: 15.5%;
  left: 9.2%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button2 {
  position: absolute;
  top: 43%;
  left: 16.5%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button3 {
  position: absolute;
  top: 78.2%;
  left: 13.7%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button4 {
  position: absolute;
  top: 53.2%;
  left: 22.45%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button5 {
  position: absolute;
  top: 29%;
  left: 23.5%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button6 {
  position: absolute;
  top: 45.5%;
  left: 26.9%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button7 {
  position: absolute;
  top: 38.6%;
  left: 37%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button8 {
  position: absolute;
  top: 63.2%;
  left: 41%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button9 {
  position: absolute;
  top: 82.7%;
  left: 42.4%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button10 {
  position: absolute;
  top: 89.6%;
  left: 35.5%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button11 {
  position: absolute;
  top: 30.7%;
  left: 43.35%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button12 {
  position: absolute;
  top: 65.3%;
  left: 48.2%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button13 {
  position: absolute;
  top: 92.4%;
  left: 52.9%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button14 {
  position: absolute;
  top: 47.5%;
  left: 57.2%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button15 {
  position: absolute;
  top: 60.25%;
  left: 59.1%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button16 {
  position: absolute;
  top: 29.4%;
  left: 61.4%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button17 {
  position: absolute;
  top: 44.95%;
  left: 64.5%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button18 {
  position: absolute;
  top: 45.2%;
  left: 73.3%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button19 {
  position: absolute;
  top: 36.95%;
  left: 70.2%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button20 {
  position: absolute;
  top: 23.6%;
  left: 77%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button21 {
  position: absolute;
  top: 14.2%;
  left: 90.6%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button22 {
  position: absolute;
  top: 31.6%;
  left: 83.3%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button23 {
  position: absolute;
  top: 59%;
  left: 89.4%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button24 {
  position: absolute;
  top: 57%;
  left: 79.85%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button25 {
  position: absolute;
  top: 57.75%;
  left: 70.57%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button26 {
  position: absolute;
  top: 67.75%;
  left: 75.2%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button27 {
  position: absolute;
  top: 85.5%;
  left: 62.57%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.videoWrapper .button28 {
  position: absolute;
  top: 43.9%;
  left: 49%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}


.videoWrapper .button29 {
  position: absolute;
  top: 26%;
  left: 28.17%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}


@primary-color: #755485;@heading-color: black;@layout-header-background: #fff;@text-color: rgba(0, 0, 0, 0.65);@modal-header-border-width: 0px;@modal-header-padding-vertical: 10px;@modal-header-padding-horizontal: 15px;@modal-body-padding: 15px;@btn-circle-size-sm: 15px;@btn-height-sm: 15px;